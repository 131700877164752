import SocketEvent from 'core/constants/SocketEvent';

const setNotificatonApi = async (
  socketInstance,
  toggleSocketNotificationsApi
): Promise<void> => {
  try {
    socketInstance.on('status_update', (data) => {
      toggleSocketNotificationsApi(data?.event_name);
    });
    socketInstance.on(
      SocketEvent.INPUT_CONFIG_FILE_UPLOAD_EVENT_NAME,
      (data) => {
        toggleSocketNotificationsApi(data?.event_name);
      }
    );
    socketInstance.on(SocketEvent.INPUT_SOURCE_CREATION_EVENT_NAME, (data) => {
      console.log('INPUT_SOURCE_CREATION_EVENT_NAME ', data);
      toggleSocketNotificationsApi(data?.event_name);
    });
    socketInstance.on(
      SocketEvent.INPUT_CUSTOM_COLUMN_CREATION_EVENT_NAME,
      (data) => {
        toggleSocketNotificationsApi(data?.event_name);
      }
    );
    socketInstance.on(
      SocketEvent.MASTER_CONFIG_FILE_UPLOAD_EVENT_NAME,
      (data) => {
        toggleSocketNotificationsApi(data?.event_name);
      }
    );
    socketInstance.on(SocketEvent.MASTER_SOURCE_CREATION_EVENT_NAME, (data) => {
      console.log('MASTER_SOURCE_CREATION_EVENT_NAME ', data);
      toggleSocketNotificationsApi(data?.event_name);
    });
    socketInstance.on(
      SocketEvent.UNIFIED_SOURCE_CREATION_EVENT_NAME,
      (data) => {
        toggleSocketNotificationsApi(data?.event_name);
      }
    );
    socketInstance.on(
      SocketEvent.UNIFIED_CUSTOM_COLUMN_CREATION_EVENT_NAME,
      (data) => {
        toggleSocketNotificationsApi(data?.event_name);
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export default setNotificatonApi;
