// Package Imports
import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { createRoot } from 'react-dom/client';
// Project Imports
import keycloak from 'keycloak/keyCloak';
import App from 'App';
import 'i18n';
import TokenProvider from 'contexts/resource/ResourceContext';
import InputSourceProvider from 'contexts/resource/inputSourceContext/InputSourceContext';
import MasterSourceProvider from 'contexts/resource/masterSourceContext/MasterSourceContext';
import useLogger from 'core/utils/useLogger';
import SocketNotificationProvider from 'contexts/resource/socketNotificationContext/SocketNotificationContext';

const container = document.getElementById('root');
const logger = useLogger();
if (container) {
  const root = createRoot(container);
  root.render(
    <TokenProvider>
      <SocketNotificationProvider>
        <InputSourceProvider>
          <MasterSourceProvider>
            <ReactKeycloakProvider authClient={keycloak}>
              <React.StrictMode>
                <React.Suspense fallback="loading">
                  <App />
                </React.Suspense>
              </React.StrictMode>
            </ReactKeycloakProvider>
          </MasterSourceProvider>
        </InputSourceProvider>
      </SocketNotificationProvider>
    </TokenProvider>
  );
} else {
  logger('Root element with id', '');
}
