// Project Imports
import apiEndpoint from 'core/common/apiEndPoints';
import axiosInstance from 'providers/axiosInstance/axiosInstance';

const fetchPrograms = async (
  setUserProgramList: React.Dispatch<React.SetStateAction<any[]>>
): Promise<void> => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL_ORG}/${apiEndpoint.getProgramRoles}/`;
    const response = await axiosInstance.get(
      //  Temporary for getting all programs - ?page=1&page_size=100
      `${apiUrl}`
    );
    const responseData = (response.data as any)?.results;
    const data = responseData.map((item) => ({
      label: item.iprogram_name,
      value: item.iprogram_unique_id,
      ...item,
    }));
    await setUserProgramList(data);
  } catch (error) {
    if (error?.response?.data) {
      setUserProgramList([]);
      console.log(error);
    }
  }
};

export default fetchPrograms;
