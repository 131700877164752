const dashboard = 'dashboard';
const inputSourceConnection = 'input_source_connections';
const inputSourceMaster = 'input_source_type_master';
const inputSourceFileUpload = 'input_data_file_upload';
const inputSourceColumn = 'input_source_column';
const inputSourceCustomColumn = 'input_source_custom_column';
const masterSourceConnection = 'master_data_source_connections';
const masterSourceColumn = 'master_data_source_column';
const masterSourceMaster = 'master_data_source_type_master';
const dataSourceList = 'data_source_list';
const userEvalPermissionDetails = 'user_eval_permission_details';
const organization = 'organization';
const dataSourceColumnList = 'data_source_column_list';
const updateUserLanguage = 'update_user_language';
const unifiedSourceDetails = 'unified_source_details';
const unifiedSourceMaster = 'unified_source_master';
const roles = 'roles';
const userRoles = 'get_user_roles_program_details';
const updateUserRoleProgramDetails = 'update_user_role_program_details';
const incentiveProgram = 'incentive_program';
const workFlowStatus = 'work_flow_status';
const getProgramRoles = 'get_program_roles';
const retryWorkflowTrigger = 'retry_workflow_trigger';

const apiEndpoint = {
  dashboard,
  inputSourceConnection,
  inputSourceMaster,
  inputSourceFileUpload,
  inputSourceColumn,
  inputSourceCustomColumn,
  masterSourceConnection,
  masterSourceColumn,
  masterSourceMaster,
  dataSourceList,
  userEvalPermissionDetails,
  organization,
  dataSourceColumnList,
  updateUserLanguage,
  unifiedSourceDetails,
  unifiedSourceMaster,
  roles,
  userRoles,
  updateUserRoleProgramDetails,
  incentiveProgram,
  workFlowStatus,
  getProgramRoles,
  retryWorkflowTrigger,
};

export default apiEndpoint;
