/* eslint-disable @typescript-eslint/no-floating-promises */
// Package Imports
import { createContext, useContext, useEffect, useState } from 'react';

// Project Imports

import setNotificatons from 'core/utils/setNotificatons';
import setNotificatonApi from 'core/utils/setNotificationApi';
import SocketEvent from 'core/constants/SocketEvent';
import { useTokenContext } from 'contexts/resource/ResourceContext';
import { SocketNotificationContextType } from 'contexts/resource/socketNotificationContext/SocketNotificationContextType';

const SocketNotificationContext = createContext<SocketNotificationContextType>({
  setNotificationMessages: () => {},
  notificationMessages: [],
  toggleInputConfig: false,
  toggleMasterConfig: false,
  toggleUnifiedConfig: false,
  toggleInputCustomCol: false,
  toggleUnifiedCustomCol: false,
  setToggleInputConfig: () => {},
  setToggleMasterConfig: () => {},
  setToggleUnifiedConfig: () => {},
  setToggleInputCustomCol: () => {},
  setToggleUnifiedCustomCol: () => {},
});

export function useSocketNotificationContext() {
  return useContext(SocketNotificationContext);
}

const SocketNotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { userDetails, socketInstance } = useTokenContext();
  const [toggleInputConfig, setToggleInputConfig] = useState(false); // input source creation
  const [toggleMasterConfig, setToggleMasterConfig] = useState(false); // master source creation
  const [toggleUnifiedConfig, setToggleUnifiedConfig] = useState(false); // unified source creation
  const [toggleInputCustomCol, setToggleInputCustomCol] = useState(false); // input custom col creation
  const [toggleUnifiedCustomCol, setToggleUnifiedCustomCol] = useState(false); // unified custom col creation
  const [notificationMessages, setNotificationMessages] = useState<any>(() => {
    const notificationList = localStorage.getItem('notifications');
    return (notificationList && JSON.parse(notificationList)) || [];
  });

  const toggleSocketNotifications = (type) => {
    if (type === SocketEvent.INPUT_CONFIG_FILE_UPLOAD_EVENT_NAME) {
      setToggleInputConfig((prev) => !prev);
    } else if (type === SocketEvent.MASTER_CONFIG_FILE_UPLOAD_EVENT_NAME) {
      setToggleMasterConfig((prev) => !prev);
    }
  };
  const toggleSocketNotificationsApi = (eventName) => {
    switch (eventName) {
      case SocketEvent.INPUT_SOURCE_CREATION_EVENT_NAME:
        // console.log('called input create');
        setToggleInputConfig((prev) => !prev);
        break;
      case SocketEvent.INPUT_CUSTOM_COLUMN_CREATION_EVENT_NAME:
        setToggleInputCustomCol((prev) => !prev);
        break;
      case SocketEvent.MASTER_SOURCE_CREATION_EVENT_NAME:
        // console.log('called inmaster create');
        setToggleMasterConfig((prev) => !prev);
        break;

      case SocketEvent.UNIFIED_SOURCE_CREATION_EVENT_NAME:
        setToggleUnifiedConfig((prev) => !prev);
        break;

      case SocketEvent.UNIFIED_CUSTOM_COLUMN_CREATION_EVENT_NAME:
        setToggleUnifiedCustomCol((prev) => !prev);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (socketInstance && userDetails) {
      setNotificatons(
        setNotificationMessages,
        userDetails,
        socketInstance,
        toggleSocketNotifications
      );
      // call function to update event
      setNotificatonApi(socketInstance, toggleSocketNotificationsApi);
    }
  }, [socketInstance]);

  // to test whether api call will happened or not when toggleMasterConfig changed
  // useEffect(()=>{
  //   setInterval(()=> setToggleMasterConfig(!toggleMasterConfig),10000)
  // },[toggleMasterConfig])
  return (
    <SocketNotificationContext.Provider
      value={{
        toggleInputConfig,
        toggleMasterConfig,
        toggleUnifiedConfig,
        toggleInputCustomCol,
        toggleUnifiedCustomCol,
        setToggleInputConfig,
        setToggleMasterConfig,
        setToggleUnifiedConfig,
        setToggleInputCustomCol,
        setToggleUnifiedCustomCol,
        notificationMessages,
        setNotificationMessages,
      }}
    >
      {children}
    </SocketNotificationContext.Provider>
  );
};

export default SocketNotificationProvider;
